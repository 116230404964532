import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { createStyles, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { defaultSliderSettings } from '../../utils/constants';
import Slider from 'react-slick';

const useStyles = makeStyles((theme: Theme) => createStyles({
  slider: {
    '& > div > div > div > div > div': {
      height: 666.5
    }
  }
}));

interface IDialogInfoProps {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  data: any,
  isGallery?: boolean,
  isVideo?: boolean ,
  setIsGallery?: Dispatch<SetStateAction<boolean>>
}

const DialogInfo: FunctionComponent<IDialogInfoProps> = ({ open, setOpen, data, isGallery, setIsGallery, isVideo}) => {
  const classes = useStyles();

  
  const handleClose = () => {
    setOpen(false);
    if (setIsGallery) setIsGallery(false);
  }

  return (

    


   /* <Dialog
      open={open}
      scroll='body'
      onClose={handleClose}
      maxWidth={!isVideo ? 'xs' : 'md'}
      transitionDuration={{ enter: 340, exit: 300 }}
    >*/
       <Dialog open={open} onClose={() => { setOpen(true) }} fullWidth>
   
      <DialogTitle disableTypography style={{ padding: 0, textAlign: 'end', backgroundColor:'transparent' }} >

        <IconButton color='primary' onClick={handleClose}>
          <Close fontSize='small' />
        </IconButton>
      </DialogTitle>

        <DialogContent style={{ overflow: "hidden" }}></DialogContent>


      <DialogContent style={{ padding: 0 , height:"100%",  backgroundColor:'black'}}>
        {!isGallery && isVideo ? 
          <video   src={data} width="100%" height="100%"  controls autoPlay  />
             : null}

        {!isGallery && !isVideo?
          <img src={data} style={{ width: '100%', display: 'block', objectFit: 'fill' }} />
        : null}
        {isGallery ? (
          <Slider {...defaultSliderSettings} dots={false} autoplay={false} className={classes.slider}>
            {data.map((item: any, index: number) => (
              <div key={index}>
                <a href={item.to} target='_blank' rel='noopener noreferrer'>
                  <img src={item.to} alt={item.name} style={{ width: '100%', height: '100%', display: 'block', objectFit: 'contain'}} />
                </a>
              </div>
            ))}
          </Slider>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default DialogInfo;
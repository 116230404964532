import { Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { defaultSliderSettings } from '../../utils/constants';
import { getApiUrl } from '../../utils/functions';
import { ISliderItem } from '../../utils/interfaces';
import DialogInfo from './DialogInfo';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  notices: {
    marginTop: theme.spacing(3),
    '& > div:nth-child(1)': {
      marginBottom: theme.spacing(2)
    }
  },
  noticesSlider: {
    '& *': {
      outline: 'none'
    },
    '& img': {
      marginTop: 0,
      maxHeight: 150,
      margin: theme.spacing(2),
      /*boxShadow: theme.overrides.MuiPaper.elevation2['boxShadow'],*/
      transition: 'transform .3s ease',
      transform: 'scale(0.95)',
      '&:hover': {
        transform: 'scale(1)'
      }
    },
    '& div.slick-current img': {
      transform: 'scale(1.15)',
      '&:hover': {
        transform: 'scale(1.2)'
      }
    }
  },
}));

const Notices = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000)
  }, []);

  const noticesSlider: Settings = {
    ...defaultSliderSettings,
    centerMode: true,
    className: classes.noticesSlider,
    autoplay: false,
    variableWidth: true,
    slidesToShow: 4,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const items: ISliderItem[] = [

    {
      to: '/transparencia-difusion/calendario-pensiones',
      name: 'Aviso 1',
      show: true,
      inside: true,
      cover: require('../../assets/images/avisos/1.png')
    },
    {
      to: '/transparencia-difusion/evaluacion-diseño-programa',
      name: 'Cuenta Pública',
      show: true,
      inside: true,
      cover: require('../../assets/images/avisos/evaluaciones.jpg')
    },
    {
      to: 'https://seed.tabasco.gob.mx/evaluacion.html',
      name: 'Programa anual de evaluación',
      show: true,
     // inside: true,
      cover: require("../../assets/images/anuncios/Banner_SEED.jpg")
    },
    {
      to: 'https://www.google.com/maps/d/edit?mid=1JRuhuEgG7Re0PMlD_Kq-7gVvVbRp3iY&usp=sharing',
      name: 'Aviso 0',
      show: true,
      //inside: true,
      cover: require('../../assets/images/avisos/mapa_unidades_medicas.png')
    },

   
    {
      to: '/transparencia-difusion/cuenta-publica',
      name: 'Cuenta Pública',
      show: true,
      inside: true,
      cover: require('../../assets/images/avisos/cuentaPublica.png')
    },
    {
      to: 'https://tramites.isset.gob.mx:6001/tramites/login',
      name: 'Aviso 2',
      cover: require('../../assets/images/avisos/2.jpg')
    },
    {
      to: `${getApiUrl('portal')}/static/docs/anuncios/12.jpg`,
      name: 'Aviso 4',
      show: true,
      modal: true,
      cover: require('../../assets/images/avisos/4.jpg')
    },
    {
      to: `${getApiUrl('portal')}/static/docs/avisos/5.pdf`,
      name: 'Aviso 5',
      cover: require('../../assets/images/avisos/5.jpg')
    },
  
    {
      to: `${getApiUrl('portal')}/static/docs/tu-instituto/marco-juridico/condiciones-generales-trabajo-2023-2025.pdf`,
      name: 'Aviso 7',
      show: true,
      cover: require('../../assets/images/avisos/7.png')
    },  
    {
      to: '/servicios-enlinea/cuentas-bancarias',
      name: 'Aviso 9',
      show: true,
      inside: true,
      cover: require('../../assets/images/avisos/9.png')
    },
    {
      to: '/transparencia-difusion/sevac',
      name: 'Aviso 10',
      show: true,
      inside: true,
      cover: require('../../assets/images/avisos/10.png')
    },
    {
      to: 'https://portalpensionados.isset.gob.mx/portalPensionadosJubilados/',
      name: 'Aviso 11',
      show: true,
      cover: require('../../assets/images/avisos/11.png')
    },
    {
      to: 'https://recibo.isset.gob.mx:6009/',
      name: 'Aviso 12',
      show: true,
      cover: require('../../assets/images/avisos/12.png')
    },
    {
      to: '/informacion-financiera',
      name: 'Aviso 13',
      show: true,
      cover: require('../../assets/images/avisos/13.png')
    }
  ]

  const handleOpenDialog = (data: string) => {
    setOpen(true);
    setData(data);
  }

  if (loading) return <>Cargando...</>
  return (
    <>
      <DialogInfo open={open} setOpen={setOpen} data={data} />

      <Grid container direction="column" className={classes.notices}>
        <Grid item xs={12}>
          <Typography variant="h2" style={{ maxWidth: 1650, margin: '0 auto', paddingLeft: 16 }}>Avisos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Slider {...noticesSlider}>
            {items
              .filter(item => item.show)
              .map((item, index) =>
                <div key={index}>
                  {item.modal ? <img src={item.cover} style={{ cursor: 'pointer' }} alt={item.name} onClick={(e) => handleOpenDialog(item.to)} /> : null}

                  {item.to && !item.modal ? (
                    <a href={item.to} target={item.inside ? '_self' : '_blank'} rel={item.inside ? '' : 'noopener noreferrer'}>
                      <img src={item.cover} alt={item.name} />
                    </a>
                  ) : null}

                  {!item.to && !item.modal ? <img src={item.cover} style={{ cursor: 'pointer' }} alt={item.name} /> : null}
                </div>
              )}
          </Slider>
        </Grid>
      </Grid>
    </>
  );
};

export default Notices;
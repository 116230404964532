import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  footer: {
    maxWidth: '100%',
    marginTop: theme.spacing(8),
    backgroundColor: grey[800],
    color: '#fff',
    textAlign: 'center'
  },
  info: {
    padding: theme.spacing(3),
    '& > *': {
      marginTop: theme.spacing(1)
    },
    '& img': {
      maxWidth: 50,
      marginTop: theme.spacing(3)
    }
  },
  copyright: {
    padding: theme.spacing(1),
    maxWidth: '100%',
    backgroundColor: grey[900]
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Grid container>
        <Grid item xs={12} className={classes.info}>
          <Typography variant="body2">Instituto de Seguridad Social del Estado de Tabasco</Typography>
          <Typography variant="body2">Gobierno del Estado de Tabasco</Typography>
          <img src={require('../assets/images/logo/logo-tabasco-blanco.svg')} />
        </Grid>
        <Grid item xs={12} className={classes.copyright}>
          <Typography variant="caption" component="small">Todos los derechos reservados © - {new Date().getFullYear()}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useWindowScroll } from "react-use"
import {
  Container,
  createStyles,
  Fab,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { ToastContainer } from "react-toastify"
import Navbar from "./navbar"
import MediaInfo from "./mediaInfo"
import Footer from "./footer"
import Notices from "./sliders/notices"
import { MarkunreadMailboxOutlined } from "@material-ui/icons"
import { Link } from "gatsby"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1650,
      padding: 0,
    },
    suggestionsMailbox: {
      position: "fixed",
      bottom: 20,
      right: 20,
      zIndex: 100,
      height:60,
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  })
)

const Layout = ({ children }) => {
  const classes = useStyles()
  const scroll = useWindowScroll()

  return (
    <>
      <ToastContainer />
      <MediaInfo />
      <Navbar />
      <Container
        component="main"
        className={classes.container}
        style={{ marginTop: scroll.y >= 34 ? "6.5rem" : 0 }}
      >
        {children}

        { <Fab style={{marginRight: 50, marginBottom:40}}
          component={Link}
          to="/suggestions-mailbox"
          variant="extended"
          color="primary"
          aria-label="Buzón de sugerencias"
          
          className={classes.suggestionsMailbox}
        >
          <MarkunreadMailboxOutlined className={classes.extendedIcon} />
          <Typography variant="caption">
            Buzón de
            <br /> sugerencias
          </Typography>
        </Fab> }

        <div style={{ padding: 16, marginTop: 50 }}>
          <Notices />
        </div>
      </Container>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
